@import "../../assets/styles/fonts";
@import "../../assets/styles/mainVariables";

.progress-wrapper {
  display: flex;
  align-items: center;
  width: 220px;

  .slide-index {
    margin: 0 16px;
    font-weight: 600;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  .progress-bar {
    width: 100%;
    position: relative;
    height: 2px;
    background: lightgray;

    .stroke {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 2px;
      z-index: 10;
      background: #A18E77;
      display: block;
      border-radius: 5px;
      will-change: width;
      &.animate {
        animation-duration: 3s;
        animation-fill-mode: both;
        animation-name: progress;
      }
    }
  }
}


@keyframes progress {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
