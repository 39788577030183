@import 'mainVariables';

.ant-select {

  &.ant-select-single {
    border: none;
  }

  &.invalid .ant-select-selection {

    border-color: #FF0000;
  }

  .ant-select-selection__placeholder {
    color: #9b9b9b;
    font-size: 13px;
  }

  .ant-select-selection--single {
    height: 35px;

    .ant-select-selection__rendered {
      line-height: 33px;

      &:focus, &:hover, &:active {
        outline: none;
      }
    }
  }

  .ant-select-search input {
    outline: none !important;
    padding: 6px 0;
  }

  &.ant-select-focused {
    .ant-select-selector {
      border: 1px solid #DDDEDF !important;
      box-shadow: 0 0 0 0 white !important;
    }
  }
}

.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: 0 0 0 3px #D0E3F3;

}

.ant-select-dropdown-menu-item {
  background-color: white;

  &:not(:last-child) {
    border-bottom: 1px solid #DDDDDD;
  }

  &:hover {
    background-color: rgba(179, 179, 179, 0.38) !important;
  }
}

.ant-select-dropdown-menu-item-active {
  background-color: rgba(255, 255, 255, 0.38) !important;
}

.ant-dropdown-menu-item:hover {
  background-color: #f1f1f1;
}
.ant-dropdown-menu-item:hover {
  background-color: #f1f1f1;
}


.ant-calendar-date {
  transition: all 0.3s ease;

  &:hover {
    color: white;
    background-color: #FF0000;
  }
}


.ant-popover-content {
  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    border-radius: 8px;
    border: 1px solid #DDDDDD;

    .ant-popover-inner-content {
      padding: 0;

    }
  }
}

.ant-dropdown-trigger {
  display: flex;
  align-items: center;
  //padding: 12px 16px 12px 0;
  color: white;

  &.upload-btn {
    padding-right: 0;
  }

  @media all and (max-width: 1000px){
    padding: 0px ;
  }
}

.ant-modal {
  width: 50% ;
  transform-origin: unset !important;
  animation-duration: unset !important;
}

.ant-breadcrumb {
  a{
    &:hover {
      color: $brandColor;
    }
  }

}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
  border-color: $brandColor;
}

.callDropDown{
  .ant-dropdown-menu-title-content{
    display: flex;
    flex-direction: column;
    a{
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      svg{
        margin-right: 8px;
      }
      &:first-child{
        svg{
          margin-right: 6px!important;
        }
      }
    }
  }
}
.item-card-content-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.03em;
  text-align: left;
  margin: 20px 0;
}
.ant-dropdown-menu-title-content > a::after{
  position: unset!important;
}
.dropDownTelephone{
  svg{
    width: 26px;
    height: 26px;
  }
    span{
      margin-top: 4px;
      font-size: 18px!important;
      font-weight: 500;
      color: #444444;
    }
}


