@font-face {
  font-family: Helvetica;
  src: url(../fonts/HelveticaNeueCyr-Light.otf) format('opentype'),
  url(../fonts/HelveticaNeue-Light.ttf) format('truetype') ;
  font-weight: 300 400;
}

@font-face {
  font-family: Helvetica;
  src: url(../fonts/HelveticaNeueCyr-Medium.otf) format('opentype'),
  url(../fonts/HelveticaNeue-Medium.ttf) format('truetype');
  font-weight: 500 600;
}

@font-face {
  font-family: Helvetica;
  src: url(../fonts/HelveticaNeueCyr-Bold.otf) format('opentype'),
  url(../fonts/Helvetica-Neu-Bold.ttf) format('truetype');
  font-weight: 700 900;
}


@font-face {
  font-family: Formular;
  src: url(../fonts/Formular.ttf) format('truetype');
  font-weight: 300 400;
}

@font-face {
  font-family: Formular;
  src: url(../fonts/Formular-Medium.ttf) format('truetype');
  font-weight: 500 600;
}

@font-face {
  font-family: Formular;
  src: url(../fonts/Formular-Bold.ttf) format('truetype');
  font-weight: 700 900;
}