
:root {
  --brandColor: #CDBB99;
  --brandSecColor: #ff7f45;
  --mainPadding: 4.5%;
}

$mainPadding: var(--mainPadding);

//--------Colors---------
$brandColor: var(--brandColor);
$brandSecColor: var(--brandSecColor);
$hoverColor:#B79B7A;
$greyColor: #F4F4F4;
$darkGreyColor: #E2E2E2;
$greyBorderColor: #F9F8F9;
$textColor: #484848;


@media (max-width: 800px) {
  :root {
    --mainPadding: 6%;
  }
}
@media (max-width: 550px) {
  :root {
    --mainPadding: 3%;
  }
}
