@import 'mainVariables';

.LayoutWrapper{
  width: 100%;
  height: 100%;

  .layoutDisable{
    display: none;
    opacity: 0;
  }
  .MainContent{
    width: 100%;
    min-height: 50%;

    @media all and (max-width: 1000px) {
      padding-top: 60px;
    }
  }
  &.mobileMenuOpen {
    overflow: hidden;
  }
  .callBtn{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: fixed;
    bottom: 42px;
    right: 40px;
    z-index: 1013;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $brandColor;
    box-shadow: 0 0 20px 0 $brandColor;
    svg{
      width: 24px;
      height: 24px;
    }
    &::after{
      opacity: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      height: 100%;
      width: 100%;
      border: 20px solid $brandColor;
      border-radius: 100%;
      animation-name: ripple;
      animation-duration: 3s;
      animation-delay: 0s;
      animation-iteration-count: infinite;
      animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
      z-index: -1;
    }

    &::before{
      opacity: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      height: 100%;
      width: 100%;
      border: 20px solid $brandColor;
      border-radius: 100%;
      animation-name: ripple;
      animation-duration: 3s;
      animation-delay: 0.5s;
      animation-iteration-count: infinite;
      animation-timing-function: cubic-bezier(.65,0,.34,1);
      z-index: -1;
    }
  }
  @keyframes ripple {
    from {
      opacity: 1;
      transform: scale3d(0.85,0.85,1);
    }

    to {
      opacity: 0;
      transform: scale3d(1.5,1.5,1);
    }
  }

  @media screen and (max-width: 1000px){
    .MainContent{
      padding-top: 100px;
    }
    //.left-block-wrapper{
    //  display: none;
    //}
    .top{
      top: unset !important;
      position: fixed!important;
    }
    .mobile-wrapper{
      width: 100%;
      height: 86px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 60px 0 30px 0;
      padding: 20px 0;
      position: absolute;
      background: white;
      top: 80px;
      .mobil-block{
        display:flex;
        width: 306px;
        height:44px;
        align-items: center;
        justify-content:space-between;
        background-color:$brandColor;
        cursor: pointer;
        &:hover{
          background: $hoverColor;
        }
        svg{
          width: 20%;
          padding-left: 16px;
        }
        span{
          width: 70%;
          font-size: 15px;
          font-weight: 400;
          line-height: 28px;
          letter-spacing: 0.03em;
          color: white;
          margin-right: 20px;
        }
      }
    }
    .menu-dropdown{
      width: 100%;
      position: fixed;
      z-index: 1000;
      background-color: white;
      height: 0;
      transition: all .3s;
      overflow: hidden;
      top: 0;

      &.open {
        overflow: auto;
        height:100vh;
        visibility: visible;
      }
      .close-icon{
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        cursor: pointer;
        svg{
          width: 24px;
          height: 24px;
          margin: 16px;
        }
      }
    }
  }
  @media screen and (max-width:600px){
    .mobile-wrapper{
      .mobil-block{
        width: 280px;
        span{
          font-size: 12px;
          width: 64%;
        }
      }
    }
  }
}
