@import "../mainVariables";

.leftBlock {
  width: 370px;
  position: fixed;
  background-color: #FEFEFE;
  left: 0;
  bottom: 0;
  top: 134px;
  overflow: scroll;
  z-index: 100;
  &::-webkit-scrollbar{
    display: none;
  }
  .HousesFilters {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: scroll;
    background-color:transparent;
    &::-webkit-scrollbar{
      display: none;
    }

    .filters {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      background-color: white;
      position: relative;
      .floor-wrapper {
        width: 330px;
        position: absolute;
        right: 0;
        display: flex;
        justify-content: space-between;
        margin-top: 17%;
        border-bottom:1px solid #00000014;
          .floor-block{
            font-size: 14px;
            cursor: pointer;
            padding-bottom: 20px;
            &.active{
              transition: right .3s ;
              border-bottom: 2px solid black;
            }
            &:last-child{
              margin-right: 44px;
            }
            &:nth-child(2){
              cursor: no-drop;
              @media screen and (max-width: 1000px){
                color:#ACACAC;
              }
            }
          }

        .floor-title {
          margin-bottom: 10px;
        }

        .field-title {
          border: 2px solid $greyBorderColor;
          height: 50px;
          padding: 0 20px;
          display: flex;
          justify-content: space-between;
        }
      }

      .filters-content {
        width: 80%;
        display: flex;
        position: relative;
        top: 22%;
        flex-direction: column;
        justify-content: space-between;

        @media screen and (max-width:1000px){
          top: 18%;
          width: 68%;
          left: 26px;
        }
      }

      .filter-top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        .block-title{
          display: flex;
          font-size: 13px;
          color: #696969;
          line-height: 18px;
          margin: 20px 0;
          cursor: unset;
        }


        .roomsCount {
          width: 100%;

          .field-title {
            margin-bottom: 10px;
          }

          .rooms-count-content {
            display: flex;
            width: 100%;
            height: 44px;
            justify-content: space-between;
            border: 1px solid #D2D2D2;
          }
          .rooms-disabled-count-item{
            border-left: 1px solid #D2D2D2;
            display: flex;
            background: transparent;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            box-sizing: content-box;
            cursor:no-drop;
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0.03em;
            text-align: center;
            &:first-child{
              border-left: unset;
            }
            @media screen and (max-width:1000px){
              background-color: #eeeeee;
            }
          }

          .rooms-count-item {
            border-left: 1px solid #D2D2D2;
            display: flex;
            background: transparent;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            box-sizing: content-box;
            cursor: pointer;
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0.03em;
            text-align: center;

            &.active {
              color: $brandColor;
            }

            &:disabled {
              cursor: no-drop;
              color: #656565;
            }

            @media screen and (min-width: 1000px){
              &:first-child{
                border-left: unset;
              }
              &:hover {
                background-color:$hoverColor;
                color: white;
              }
            }

            &.active {
              border-color: transparent !important;
              background-color:$brandColor;
              color: white;

              & + .rooms-count-item {
                border-color: transparent !important;
              }
            }
          }
        }

        .slider-filter {
          width: 100%;
          .floors-select{
            .custom-input-group{
              height: 44px;
              .ant-select{
                height: 100%;
                .ant-select-selector{
                  height: 100%;
                  display: flex;
                  align-items: center;
                  .ant-select-selection-item{
                    display: flex;
                    align-items: center;
                  }
                }
              }
            }
            .disable-input{
              cursor: no-drop;
              .custom-input-group{
                height: 44px;
                cursor: no-drop;
                input{
                  height: 100%;
                  width: 100%;
                  cursor: no-drop;
                }
              }
            }
          }

          .border-block{
            height: 44px;
            padding: 4px 4px 0 4px;
            border: 1px solid #D2D2D2;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
          }
          .slider-filter-top {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .left-block{
              width: 50%;
              display: flex;
              align-items: center;
              justify-content: flex-start;
            }
            .right-block{
              width: 50%;
              display: flex;
              align-items: center;
              justify-content: flex-end;
            }
            span{
              color: #ACACAC;
              margin: 6px;
              font-size: 13px;
              font-weight: 400;
              line-height: 18px;
              letter-spacing: 0.03em;
              text-align: left;

            }
            .filters-area{
              color: #282825;
              font-size: 13px;
              line-height: 18px;
              display: flex;
              align-items: center;
              font-weight: 400;
              letter-spacing: 0.03em;
              text-align: left;
              p{
                margin-left: 4px;
                color:black;
              }

              svg{
                margin-left: 6px;
              }
            }
          }

          .slider-filter-bottom {
            width: 100%;
            display: flex;
            justify-content: space-between;

            div {
              font-weight: 700;
              font-size: 15px;
              color:$brandColor;
            }

            span {
              font-weight: 500;
              color:$textColor;
              margin-left: 10px;
            }

          }
        }
      }

      .filter-down {
        margin-bottom: 20px;
        height: 80px;
        display: flex;
        align-items: unset;

        button {
          cursor: pointer;
          background-color: transparent;
          border: 0;
          display: flex;
          align-items: center;
          font-size: 13px;
          color: #696969;
          line-height: 18px;

          svg {
            margin-top: 3px;
            margin-left: 10px;

            polygon {
              fill: #F83636;
            }
          }
        }
      }

      .filters-count-wrapper {
        width: 80%;
        height: 44px;
        padding: 10px;
        background-color:#CDBB99;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        margin-top: 132px;
        .display-none{
          display: none!important;
        }
          .filters-count {
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0.03em;
            margin-right: 6px;
          }
          span{
            font-size: 14px;
            font-weight: 400;
            line-height: 15px;
            letter-spacing: 0.03em;
            text-align: left;
          }
        @media screen and (max-width: 1000px){
          margin-top: 94px;
          width: 68%;
          margin-left: 50px;
        }
        &:hover{
          background-color: $hoverColor;
        }
      }
    }

    .houses-wrapper {
      width: 100%;
      padding: 40px $mainPadding;

      .sort-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;

        .left-side {
          display: flex;
          align-items: center;
          color: $textColor;
          font-size: 16px;
          span{
            font-weight: 500;
          }

          .sort-item {
            display: flex;
            color: black;
           font-weight: 500;
            margin-left: 1.6rem;
            cursor: pointer;

            .buttons {
              display: flex;
              flex-direction: column;
              justify-content: center;

              svg {
                width: 12px;
                height: 8px;
                cursor: pointer;
                margin-left: 10px;
                transition: all 0.2s;
                &.active{
                  polygon{
                    fill: black;
                  }
                }
              }
            }
          }
        }
        .status-sort{
          cursor: pointer;
        }
      }

      .houses-list {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% + 20px);
      }
    }
  }

  .filter-floors-list {
    padding: 20px 15px;
    flex-wrap: nowrap;
    width: 282px;
    box-shadow: 0 0 7px 4px #f8f8f8;
    background-color: white;
    border: 0.5px solid $greyBorderColor;

    .list-item {
      width: 50px;
      height: 50px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      font-weight: 700;
      cursor: pointer;
      transition: .2s;
      border: 0.5px solid $greyBorderColor;

      &.active {
        background-color: black;
        color: white;
      }

      &.inactive-floor {
        cursor: default;
        background-color: $darkGreyColor;
      }

      @media all and (min-width: 1000px) {
        &:not(.inactive-floor):hover {
          background-color: black;
          color: white;
          border: 0.5px solid black;
        }
      }
    }

    .btnsWrapper {
      display: flex;
      margin-top: 10px;
      justify-content: space-between;

      .PrimaryButton {
        font-weight: 500;
        padding: 0 25px;
        width: calc((100% - 12px) / 2);

        &:first-child {
          color: #202020;
          background: #EFEFF0 !important;

          &:hover {
            background: #e2e2e2 !important;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1000px){
    width: 100%;
    top: 45px!important;
    .filters{
      .floor-wrapper{
        margin-top: 6% !important;
        width: 80% !important;
        justify-content: flex-start!important;
        .floor-block{
          &:nth-child(2){
            margin-left: 30px;
          }
          &:last-child{
            margin-left: 30px;
          }
        }
      }
      .filter-top{
        align-items: flex-start!important;
        .roomsCount{
          width: 40%!important;
          .rooms-count-content{
            width:calc(680px / 4)!important;
          }
        }
      }
    }
  }

  @media screen and (max-width: 500px){
    .HousesFilters{
      .filters{
        .floor-wrapper{
          width: 90%!important;
        }
        .filters-content{
          width: 80%;
          left: unset;
        }
        .filters-count-wrapper{
          width: 80%;
          margin-left: unset;
        }
      }
    }
  }
}
