@import "../../assets/styles/fonts";
@import "../../assets/styles/mainVariables";


.coming-soon-wrapper{
  width: 100vw;
  height: 100vh;
  .coming-soon-header{
    height: 88px;
    padding: 0 $mainPadding;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .middle-block{
    width: 100%;
    height:calc(100% - 224px);
    position: relative;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .bg-color{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(194.05deg, rgba(0, 0, 0, 0) 60.88%, rgba(0, 0, 0, 0.5) 88.95%);
      z-index: 1;
    }
    .info-block{
      max-width: 684px;
      position: absolute;
      bottom: 50px;
      left: $mainPadding;
      z-index: 3;
      font-size: 32px;
      font-weight: 500;
      line-height: 56px;
      letter-spacing: -0.03em;
      text-align: left;
      color: white;
      text-transform: uppercase;
      span{
        background-color: #A0BA3B;
        text-align: center;
        padding-top: 6px;
      }
    }
  }
  .footer-block{
    height: 136px;
    background-color: #272727;
    padding: 0 $mainPadding;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .phoneNumber-block{
      .phone-title{
        font-size: 16px;
        font-weight: 500;
        line-height: 40px;
        letter-spacing: -0.01em;
        text-align: left;
        color: white;
      }
      a{
        span{
          font-size: 28px;
          font-weight: 500;
          line-height: 40px;
          letter-spacing: -0.01em;
          text-align: left;
          color: white;
        }
      }
    }
    .sociLinks-block{
      display: flex;
      align-items: center;
      a{
        margin-left: 14px;
        img{
          width: 28px;
          height: 28px;
        }
      }
    }
  }

  @media screen and (max-width: 600px){
    .coming-soon-header{
      padding: 12px $mainPadding;
      height: 56px;
      .logo-block{
        img{
          width: 91px;
          height: 32px;
        }
      }
    }
    .middle-block{
      height:calc(100% - 142px);
      .info-block{
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: -0.03em;
        text-align: left;
        bottom: 34px;
      }
    }
    .footer-block{
      height: 86px;
      .phoneNumber-block{
        .phone-title{
          font-size: 13px;
          font-weight: 500;
          line-height: 15.9px;
          letter-spacing: -0.01em;
          text-align: left;
          margin-bottom: 10px;
        }
        a{
          span{
            font-size: 20px;
            font-weight: 500;
            line-height: 24.46px;
            letter-spacing: -0.01em;
            text-align: left;
          }
        }
      }
    }
  }
}