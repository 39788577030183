@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/fonts";


.image-wrapper {
  width: 100%;
  position: relative;
  height: calc(100vh - 80px);

  .main-slider-items {
    position: relative;
    width: 100%;
    height: 100%;

    .main-slider-inner-part {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .slider-bottom-content {
      position: absolute;
      bottom: 80px;
      left: 8%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .slider-arrows{
        display: flex;
        align-items: center;
        padding: 16px;
        background: rgba(254, 254, 254, 0.9);
        backdrop-filter: blur(2px);
        .prev-btn{
          margin: 7px 20px 0 0 ;
          cursor: pointer;
          svg{
            width: 10px;
            height: 18px;
            &:hover{
              path{
                stroke:$brandColor;
              }
            }
          }
        }
        .next-btn{
          margin: 7px 0 0 20px ;
          cursor: pointer;
          svg{
            width: 10px;
            height: 18px;
            &:hover{
              path{
                stroke:$brandColor;
              }
            }
          }
        }
      }

      .slider-bottom-main-content {
        display: flex;
        justify-content: space-between;
        padding: 44px 40px;
        background-color: white;
        width: 100%;
        border-radius: 0 4px 4px 4px;

        .main-content-items {
          display: flex;

          .item-card {
            display: flex;
            align-items: center;
            margin-right: 160px;

            .icon-wrapper {
              height: 50px;
              width: 50px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 6px;
              background-color: #F5F6F7;
              margin-right: 20px;
              transition: .2s;
              &:hover {
                background-color: $brandColor;
              }

              svg {
                path {
                  fill: #202020;
                }
              }
            }

            .item-card-content-title {
              color: #BFBCC2;
              font-weight: 500;
              margin-bottom: 2px;
              font-size: 14px;
            }

            .item-card-content-text {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              font-weight: 500;
              font-size: 16px;
            }
          }
        }
        .PrimaryButton {
          height: 50px;
        }
      }
    }
  }

  .slick-slider {
    height: 100%;

    .slick-list {
      padding: 0;
    }

    .slider-item {
      img {
        width: 100%;
        height: calc(100vh - 80px);
        object-fit: cover;
      }

      .slider-info-wrapper {
        position: absolute;
        top: 0;
        left: $mainPadding;
        height: 100%;
        width: 100%;
        display: flex;

        .info-content {
          display: flex;
          justify-content: space-around;
          flex-direction: column;
          height: 45%;
          position: relative;
          margin-top: 7%;
          width: 100%;
        }

        .slide-title {
          font-weight: 700;
          font-size: 62px;
          overflow: hidden;
          line-height: 90px;
          width: 50%;
          height: 176px;
        }

        .slide-sub-title {
          font-size: 20px;
          line-height: 34px;
          width: 50%;
          height: 122px;
          overflow: hidden;
          letter-spacing: 0.4px;
        }

        .link-button {
          width: 218px;
          height: 60px;
        }
      }
    }
  }
}


@media all and (max-width: 1600px) {
  .image-wrapper {

    .slick-slider {

      .slider-item {

        .slider-info-wrapper {

          .slide-title {
           font-weight: 700;
            font-size: 58px;
            overflow: hidden;
            line-height: 77px;
            width: 60%;
            height: 200px;
          }
          .slide-sub-title {
            width: 45%;
          }

          .link-button {
            width: 182px;
            height: 52px;
          }
        }
      }
    }
  }
}

@media all and (max-width: 1500px) {
  .image-wrapper {

    .main-slider-items {

      .slider-bottom-content {
        .slider-bottom-main-content {
          .main-content-items {
            .item-card {
              margin-right: 100px;

            }
          }
        }
      }
    }

    .PrimaryButton {
      margin-left: 0;
    }
  }
}
@media all and (max-width: 1300px) {
  .image-wrapper {

    .slick-slider {

      .slider-item {

        .slider-info-wrapper {

          .slide-title {
            font-size: 48px;
            overflow: hidden;
            line-height: 56px;
            width: 55%;
            height: 112px;
          }

          .slide-sub-title {
            font-size: 20px;
            height: 105px;
          }
        }
      }
    }

    .main-slider-items {

      .slider-bottom-content {
        .slider-bottom-main-content {
          .main-content-items {
            .item-card {
              margin-right: 30px;

            }
          }
        }
      }
    }

    .PrimaryButton {
      margin-left: 0;
    }
  }
}

@media all and (max-width: 1000px) {
  .image-wrapper {
    .slick-slider{

      .slider-item {

        img {
          object-fit: cover !important;
        }
      }
    }
    .main-slider-items {

      .slider-bottom-content {
        left: 10%;
        .slider-bottom-main-content {
          padding: 34px 40px;
          .main-content-items {
            .item-card {
              margin-right: 0;

              .item-card-content {
                display: none;
              }
            }
          }
        }
      }
    }

    .PrimaryButton {
      margin-left: 75px;
    }
  }
}

@media all and (max-width: 800px) {
  .image-wrapper {
    height: unset;

    .main-slider-items {
      padding-top: 120%;
      .slider-bottom-content {
        bottom: 12%;
        .slider-arrows{
          width: 270px;
          .prev-btn{
            margin:7px 10px 0 0!important;
          }
          .next-btn{
            margin: 7px 0 0 10px!important;
          }
        }
      }
    }

    .slick-slider {

      .slick-list {
        height: 100%;

        .slick-track {
          height: 100%;
          .slick-slide {
            div {
              height: 100%;
            }
          }
        }
      }



      .slider-item {
        img {
          object-fit: cover!important;
        }

        .slider-info-wrapper {
          .info-content {
            height: 38% !important;
          }

          .slide-title {
            width: 80%;
          }

          .slide-sub-title {
            height: 75px !important;
            width: 70%;
          }
        }
      }
    }

    .arrow-wrapper {
      bottom: 6px;

      .prev_btn {
        margin-right: 6px;
      }

      svg {
        width: 24px;
      }
    }
  }
}
@media screen and (max-width:680px){
  .image-wrapper{
    .main-slider-items {
      .slider-bottom-content {
        bottom: 12%;
      }
    }
  }
}

@media all and (max-width: 600px) {
  .image-wrapper {
    .slick-slider {

      .slider-item {

        .slider-info-wrapper {
          .info-content {
            height: 42% !important;
          }

          .slide-title {
            font-weight: 700;
            font-size: 32px;
            line-height: 46px;
            height: 100px !important;
          }

          .slide-sub-title {
            font-size: 16px;
            line-height: 25px;
            width: 60%;
          }
        }
      }
    }

    .main-slider-items {
      .slider-bottom-content {
        bottom: 12%;
      }
      .slider-bottom-content {

        .arrows-wrapper {
          bottom: 4px;

          .arrows-text {
            padding: 8px 16px;
            font-size: 14px;
          }

          .arrows {
            .slider-btn {
              width: 38px;
              height: 38px;

              svg {
                width: 14px;
              }
            }
          }

          .prev_btn {
            margin-right: 6px;
          }

          svg {
            width: 20px;
          }
        }

        .slider-bottom-main-content {
          padding: 20px 16px;

          .main-content-items {
            .item-card {
              .icon-wrapper {
                width: 40px;
                height: 40px;
                border-radius: 4px;
                margin-right: 14px;
              }
            }
          }

          .PrimaryButton {
            height: 40px;
            padding: 0 20px;
            font-size: 14px;
            margin-left: 0;
          }
        }
      }
    }
  }
}

@media all and (max-width: 500px) {
  .image-wrapper {
    .slick-slider {

      .slider-item {

        .slider-info-wrapper {
          .slide-title {
            font-weight: 700;
            font-size: 22px;
            line-height: 30px;
            width: 75%;
            height: 65px !important;
          }

          .slide-sub-title {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            width: 75%;
            height: 85px !important;
          }
        }
      }
    }
    .slider-bottom-content{
      bottom: 48px!important;
      .slider-arrows{
        .progress-wrapper{
          width: 180px!important;
        }
      }
    }
  }
}

@media all and (max-width: 370px) {
  .image-wrapper {
    .slick-slider {

      .slider-item {

        .slider-info-wrapper {
          .slide-title {
            font-weight: 700;
            font-size: 22px;
            line-height: 30px;
            width: 80%;
            height: 65px !important;
          }

          .slide-sub-title {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            height: 60px !important;
          }
        }
      }
    }

    .main-slider-items {

      .slider-bottom-content {

        .arrows-wrapper {
          bottom: 4px;

          .arrows-text {
            padding: 8px;
            font-size: 14px;
          }

          .prev_btn {
            margin-right: 6px;
          }

          svg {
            width: 20px;
          }
        }

        .slider-bottom-main-content {
          padding: 20px 8px;

          .main-content-items {
            .item-card {
              .icon-wrapper {
                width: 32px;
                height: 32px;
                svg {
                  width: 16px;
                }
              }
            }
          }

          .PrimaryButton {
            height: 32px;
            padding: 0 12px;
            font-size: 12px;
          }
        }
      }
    }

    .main-slider-items {
      .slider-bottom-content {
        .slider-bottom-main-content {
          .main-content-items {
            .item-card {
              margin-right: 0;

              .item-card-content {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
