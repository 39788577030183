@import "../../../assets/styles/fonts";
@import "../../../assets/styles/mainVariables";

.ProfitableOffer{
  padding-bottom: 68px;
  margin-top: 80px;
  .apartment-title-block{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .apartment-title{
      width: 44%;
      color: #282825;
      font-weight: 700;
      font-size: 33px;
      line-height: 40px;
      letter-spacing: 0.03em;
      text-align: left;
    }
  }
  .title-block{
    width:calc(100% - 38px);
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    .title{
      width: 24%;
      color: #282825;
      font-weight: 700;
      font-size: 33px;
      line-height: 40px;
      letter-spacing: 0.03em;
      text-align: left;

    }
    .description{
      width: 58%;
      color: #282825;
      font-weight: 400;
      font-size: 17px;
      line-height: 28px;
      letter-spacing: 0.03em;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;

    }
  }
  .offer-slider-block{
    width: 100%;
    .slick-slide{
      padding: 30px 20px;
      .slick-list{
        display: flex;
      }
    }
    .slick-active{
      div{
        .CardWrapper{
        .house-card{
          box-shadow: 3px 10px 37px 0 #00000014;
        }
        }
      }
    }
  }
  @media screen and (max-width: 1300px){
   margin-top: 20px;
    .title-block{
      .title{
        font-size: 24px;
      }
      .description{
        font-size: 14px;
      }
    }
  }

  @media screen and (max-width: 1000px){
    margin: 60px 0;
    .title-block{
      padding-left: 26px;
      flex-direction: column;
      .title{
        width: 100%;
      }
      .description{
        display: contents;
        width: 100%;
      }
    }
    .apartment-title-block{
      .apartment-title{
        width: 100%;
        font-size: 24px;
      }
      .desktop-arrows{
        justify-content: flex-start;
        padding-left: 18px;
      }
    }
    .offer-slider-block{
      .desktop-arrows{
      justify-content: flex-start;
        padding-left: 18px;
      }
      .slick-slide{
        padding-right: unset!important;
        .CardWrapper{
          margin-top: 30px;
        }
      }
    }
  }
}
