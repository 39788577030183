@import "../../../assets/styles/fonts";
@import "../../../assets/styles/mainVariables";


.call-modal{
  .ant-modal{
    width: 590px !important;
    transform-origin: unset !important;
    animation-duration: unset !important;
  }
  .ant-modal-content{
    .ant-modal-close{
      top: 6px;
      right: 2px;
      .ant-modal-close-x{
        color: black;
        font-size: 22px;
      }
    }
    .ant-modal-header{
      height: 68px;
      display: flex;
      align-items: center;
      background-color:$brandColor;
      border-radius: unset!important;
      .ant-modal-title{
        color: black;
        font-size: 17px;
      }
    }
    .ant-modal-body{
      display: flex;
      flex-direction: column;
      padding: 35px;
      .first-block{
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
      .second-block{
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
      .checked-block{
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .custom-input-group {
        width: 100%;
      }
      .bottom-block{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .custom-input-group {
          width: 48%!important;
          input{
            margin-top: 4px;
            margin-bottom: 8px;
          }
        }
        .ant-picker-input{
          height: 36px!important;
          border-bottom: 1px solid #D2D2D2;
        }

        @media screen and (max-width: 1000px){
          flex-direction: column;
          align-items: flex-start!important;
          .custom-input-group{
            width: 100%!important;
            .ant-picker{
              margin-left: unset!important;
              width: 100%!important;
            }
          }
          .checkboxWrapper{
            margin-left: 20px!important;
            .ant-checkbox-wrapper{
              height: 36px;
              margin-left: unset!important;
            }
          }
        }
      }
    }
    .ant-modal-footer{
      border: 0;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector{
      border: 0;
    }
    .ant-select-selector{
      height: 52px !important;
      width: 100% !important;
      border-radius: 0 !important;
      padding: 0 !important;
    }
    .valid{
      width: 45%;
      height: 53px;
    }
    .err{
      width: 45%;
      height: 53px;
      border-bottom: 1.2px solid red;
    }
    .ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector{
      border: 0;
    }
    .errBorder .ant-checkbox-inner{
      border: 1.2px solid red !important;
    }
    .error .ant-checkbox-inner{
      border: 1.2px solid red !important;
    }
    .checkboxWrapper{
      margin-left: 20px!important;
      .ant-checkbox-wrapper{
        .ant-checkbox{
          margin-bottom: 7px;
          .ant-checkbox-input{
            &:focus{
              border:1px solid $brandColor!important;
            }
            &:hover{
              border:1px solid $brandColor!important;
            }
          }
        }
      }
    }
    .ant-checkbox-wrapper{
      display: flex;
      align-items: center;
    }
    .ant-select-selection-item{
      padding-top:10px !important;
    }
    .ant-checkbox-inner{
      width: 18px;
      height: 18px;
    }
    .ant-checkbox-checked .ant-checkbox-inner::after{
      border: 1.5px solid #252629;
      border-left: 0;
      border-top: 0;
    }
    .ant-picker{
      padding: 0 ;
      width: 100%;
      border: 0;
      .custom-input-group input:not(.ant-select-search__field):focus{
        border: unset!important;
      }
      input{
        border-radius: 0;
        border: unset!important;
      }
      .ant-picker-suffix{
        position: absolute;
        right: 0;
      }
    }
    .ant-select-selection-placeholder{
      margin-top: 10px;
      color: #949BA4;
    }
    .ant-picker-focused{
      box-shadow: unset;
    }
    .ant-modal-footer{
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      padding:0 35px 40px 35px;
      .ant-btn {
        &.ant-btn-default {
          width: 100px;
          height: 44px;
          font-size: 13px;
          color: black;
          border-radius: unset!important;
          border-color: #EEEEEE !important;
          background: #EEEEEE!important;
        }
        &.ant-btn-primary{
          width: 100px;
          height: 44px;
          font-size: 13px;
          color: white;
          border-radius: unset!important;
          border-color: $brandColor !important;
          background: $brandColor!important;
          &:hover{
            background:$hoverColor!important;
          }
        }
      }
    }
    .ant-select-arrow{
      right: 0;
      svg{
        path{
          fill:#949BA4 !important; ;
        }
      }
    }
    .ant-checkbox + span{
      padding: 0 0 0 8px;
      height: 64px;
      overflow: hidden;
      display: flex;
      align-items: center;
    }
  }

  @media screen  and (max-width: 800px){
    .ant-modal{
      height: 580px;
    }
    .ant-modal-body{
      .first-block{
        display: flex;
        flex-direction: column;
      }
      .second-block{
        display: flex;
        flex-direction: column;
      }
      .checked-block{
        margin-top: 30px;
      }
      .custom-input-group {
        width: 100% !important;
      }
    }
    .ant-modal-content .ant-picker{
      margin-left: 24%;
      width: 76% !important;
    }
    .valid{
      width: 100% !important;
    }
    .err{
      width: 100% !important;
    }
    .checkboxWrapper{
      .ant-checkbox-wrapper{
        margin-left: 20%;
      }
    }
  }
}