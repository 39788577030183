@import "../../assets/styles/fonts";
@import "../../assets/styles/mainVariables";


.AddressWrapper {
  display: flex;
  justify-content: end;
  align-items: center;
  padding-right: 7%;
  padding-bottom: 14px;

  .address-left-wrapper {
    margin-top: 20px;
    margin-right: 50px;

    .address-title {
      font-size: 20px;
      text-transform: uppercase;
      font-weight: 700;
      display: inline-block;
      margin-bottom: 5px;
    }
  }

  .address-right-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 20px;
    .parking-icon-wrapper{
      height: 50px;
      width: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color:$brandColor;
      margin-right: 20px;
      transition: .2s;
      svg{
        width: 26px;
        height: 26px;
      }
      &:hover{
        background-color:$hoverColor;
      }
    }

  }

  .item-card {
    display: flex;
    align-items: center;

    .icon-wrapper {
      height: 50px;
      width: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color:$brandColor;
      transition: .2s;
      svg{
        width: 26px;
        height: 26px;
      }
      &:hover{
        background-color:$hoverColor;
      }
    }

    .item-card-content-title {
      color: #BFBCC2;
      font-weight: 500;
      margin-bottom: 2px;
      font-size: 14px;
    }

    .item-card-content-text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: 500;
      font-size: 16px;
    }
  }
  @media screen and (max-width: 1000px){
    position:absolute;
    width: 100%;
    background: white;
    top:-170px;
    padding-right: 4%!important;

  }
  @media screen and (max-width: 450px){
    .address-left-wrapper{
      margin-right: 30px;
    }
    .address-right-wrapper{
      .item-card{
        .parking-icon-wrapper{
          width: 42px!important;
          height: 42px!important;
        }
        .icon-wrapper{
          width: 42px!important;
          height: 42px!important;
        }
      }
    }
  }
}