@import "./assets/styles/antd-styles-overrides";
@import "./assets/styles/mainVariables";
@import "./assets/styles/fonts";


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body, #root{
  width: 100% !important;
  height: 100%;
  overflow: unset !important;
  font-family: Helvetica, Mardoto, -apple-system, BlinkMacSystemFont,
  'Segoe UI', Roboto, 'Oxygen','Ubuntu','Cantarell', 'Fira Sans',
  'Droid Sans', sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
}

button {
  cursor: pointer;
  border: none;
  padding: 0;
  user-focus: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);

  &:focus {
    outline: none;
  }
}
img{
  user-focus: none;
  user-select: none;
}

p {
  margin-bottom: 0;
}

a, a:hover {
  text-decoration: none;
  color: inherit;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.custom-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10101;
  overflow: hidden;

  .ant-modal {
    width: 100% !important;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0;
    top: 0;

    .ant-modal-header, .ant-modal-footer, .ant-modal-close {
      display: none;
    }

    .ant-modal-body {
      padding: 0;
    }

    .ant-modal-content {

      position: static;
      background-color: transparent;
    }

    .slider-modal-content {
      border-radius: 8px;
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-position: center;

      .dialog-closing-btn {
        top: 80px;
        width: 40px;
        height: 40px;
        opacity: 0;

        svg {
          width: 100%;
          height: 100%;

          polygon {
            fill: white;
          }
        }

        &.show {
          transition: opacity 0.2s;
          opacity: 1;
        }
      }
    }

  }
}
.ant-slider-track{
  background-color:#CDBB99;
}
.ant-slider:hover .ant-slider-track{
  background-color: #CDBB99;
}
.ant-slider-handle{
  background-color:#CDBB99;
  border: 0;
  &:focus{
    box-shadow: unset;
  }
}
.ant-slider-handle-dragging.ant-slider-handle-dragging.ant-slider-handle-dragging{
  box-shadow: none;
}
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open){
  border-color: #CDBB99;
}
.ant-slider{
  width: 85%;
  justify-content: center;
  margin: unset;
  height: 100%;
}
.ant-tabs-tab{
  color: #282825;
  font-size: 15px;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0.03em;
}
.ant-tabs-tab .ant-tabs-tab-active{
  color: #282825;
  font-size: 15px;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0.03em;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: #282825;
}
.ant-tabs-tab-btn:focus{
  color: #282825 !important;
}
.ant-tabs-tab:hover{
  color: #282825 !important;
}
.ant-tabs-ink-bar {
  background: #282825 ;
}
.ant-slider-rail{
  border-bottom: 1px solid #CDBB99;
  border-radius: unset;
  background-color: transparent;
}
.ant-slider:hover .ant-slider-rail{
  background-color: transparent;
}
.ant-tabs{
  width: 100% !important;
}
.ant-slider{
  position: absolute;
  top: 35px;
  height: unset;
}
.ant-slider-track{
  height: 0!important;
}
.ant-breadcrumb-link{
  color:#CDBB99;
}
.ymaps-2-1-79-gotoymaps__container{
  display: none !important;
}
.ymaps-2-1-79-gototech{
  display: none!important;
}
.ymaps-2-1-79-copyright__wrap{
  display: none !important;
}
.ymaps-2-1-79-float-button{
  border: 1px solid #C9CEE0!important;
  border-radius: unset !important;
  box-shadow: unset !important;
  background: rgba(254, 254, 254, 0.9) !important;
  &:hover{
    border: 1px solid #FFFFFFFF!important;
    background:$hoverColor!important;
  }
}
.ymaps-2-1-79-float-button-icon_icon_expand {
  background-image:url("https://api.retroshin.am/admin/files/For-map/Group-2.svg")!important;
  &:hover{
    background-image: url("https://api.retroshin.am/admin/files/For-map/Group-2--1-.svg") !important;
  }
}
.ymaps-2-1-79-float-button-icon_icon_collapse{
  background-image:url("https://api.retroshin.am/admin/files/For-map/arrows.svg") !important;
  &:hover{
    background-image:url("https://api.retroshin.am/admin/files/For-map/Group-1.svg")!important;
  }
}
.ant-switch-checked{
  background-color:#CDBB99 ;
  box-shadow: unset!important;
}
.ant-switch-checked:focus{
  -webkit-box-shadow:unset !important;
  box-shadow: unset !important;
}
.ant-modal-content{
  border-radius: unset !important;
}

.ant-dropdown-open{
  .status-sort-icon{
    transform: rotate(180deg) !important;
  }
}

.ant-dropdown-menu-title-content{
  display: flex;
  align-items: center;
}

.ant-picker-footer{
  display: none !important;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-disabled .ant-picker-time-panel-cell-inner{
  display: none !important;
}
.popup-input-time{
  &.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel{
    overflow: hidden;
    height: 284px;
    ::-webkit-scrollbar{
      display: none;
    }
  }
  .ant-picker-time-panel-column::after{
    display: none!important;
  }
}
.ant-modal-wrap{
  z-index: 1020;
  display: flex;
  align-items: center;
  justify-content: center;
}
.error{
  input{
    border-bottom: 1.2px solid red !important;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner{
  background: $brandColor;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover{
  background:$brandColor!important;
}
.cls-5{
  fill: #998771!important;
}
.ant-popover{
  top: 292px!important;
  z-index: 999!important;
}
.ant-popover-inner{
  border-radius: unset!important;
  border: unset!important;
  padding: 6px 10px 10px 14px;
}
.social-media{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  button{
    margin-top: 6px;
    p{
      width: 100px;
      display: flex;
      align-items: center;
      font-size: 13px;
      svg{
        margin-right: 6px;
      }
    }
  }
}
.ant-checkbox-inner{
  border-radius: 4px!important;
  border-color:#DDDEDF!important;
}
.error{
  .ant-picker-input{
   border-bottom:1px solid red!important;
  }
}
.like-houses-block{
  .slick-track{
    display: contents!important;
  }
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
  background-color: #f6f1ea!important;
}
.ant-select-selector{
  border-radius: 0!important;
}
.ant-modal-mask{
  z-index: 1018!important;
}
#result-pdf{
  padding: 20px 40px;
  position: relative;
  .pdf-logo{
    width: 220px;
    height: 80px;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .pdf-img{
    position: absolute;
    left: 180px;
    top: 210px;
    width: 300px;
    img{
      width: 100%;
      height: 100%;
    }
  }
}
.ant-select-dropdown{
  z-index: 100000!important;
}
.buildings-text *{
  font-family: inherit !important;
}
.slick-track{
  margin-left: unset!important;
}
