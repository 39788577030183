@import "../../../assets/styles/fonts";
@import "../../../assets/styles/mainVariables";

.HomepageAbout {
  padding: calc(#{$mainPadding} / 2) 40px 100px 0;
  .HomepageAboutTitle {
    font-size: 40px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: calc(#{$mainPadding} / 2 + 6px);
  }

  .HomepageAboutContent {
    width: 100%;
    height: 450px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-top: 130px;

    .FeaturesWrapper {
      height: 100%;
      font-size: 16px;
      width: 48%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 8px;
      align-items: flex-start;

      .features-text *{
        font-family: inherit !important;
      }

      .features-title {
        width: 70%;
        color: #282825;
        font-weight: 700;
        font-size: 33px;
        line-height: 40px;
        margin-bottom: 50px;
        letter-spacing: 0.03em;
        text-align: left;

      }

      .features-text {
        flex-grow: 1;
        color: #484848;
        line-height: 22px;
        column-count: 1;
        p{
          font-size: 14px!important;
          line-height: 22px;
          overflow: hidden;
          margin-bottom: 20px;
          column-count: 1;
          font-weight: 400;
          letter-spacing: 0.05em;
        }
      }


      .features {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 70px;

        .features-item {
          width: 50%;
          display: flex;
          align-items: center;
          transition: .2s;

          img {
            width: 50px;
            height: 50px;
            margin-right: 20px;
            transition: .2s;
          }
          &:hover img {
            transform: scale(1.1);
          }

          span {
            width: calc(100% - 70px);
            line-height: 22px;
            font-size: 14px;
            letter-spacing: 0.03em;
            text-align: left;
            &::first-line {
              word-spacing: 100vw;
            }
          }

          &:nth-child(2n) {

            img {
              margin-left: 20px;
            }

            span {
              width: calc(100% - 90px);
            }
          }


          &:first-child {
            padding: 0 20px 20px 0;
          }

          &:last-child {
            padding: 20px 0 0 0;
          }

          &:nth-child(2) {
            padding: 0 0 20px 0;
          }

          &:nth-child(3) {
            padding: 20px 20px 0 0;
          }
        }
      }
    }

    .linkWrapper {
      width: 78%;
      line-height: 26px;
      letter-spacing: 0.4px;
      margin-bottom: 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span{
        width: 50%;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.03em;
        text-align: left;

      }
      .features-btn-see-all {
        height: 44px;
        background-color: $brandColor;
        color: white;
        font-size: 14px;
        padding: 0 25px 0 25px;
        display: flex;
        align-items: center;
        line-height: 24px;
        letter-spacing: 0.03em;
        text-align: left;

        svg{
          margin-left: 17px;
        }
        &:hover{
          transition: transform 0.7s;
          background-color: $hoverColor;
        }
      }

    }

    .btnsWrapper {
      display: flex;
      justify-content: space-between;

      button {
        height: 58px;
        text-transform: uppercase;
        width: calc(50% - 10px);
      }
    }

    .About-img-wrapper{
          width: 36%;
          border: 2px solid $brandColor;
          .img-block{
            position: relative;
            right: 40px;
            bottom: 40px;
            padding-top: 97.6%;
            img{
              width: 100%;
              height: 100%;
              position:  absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              object-fit: cover;
            }
          }
    }
  }

  @media all and (max-width: 1600px) {

    .HomepageAboutContent {

      .slider-wrapper {
        width: 52%;
        margin-right: 0;
      }

      .FeaturesWrapper {
        .features-title {
          margin-bottom: 30px;
          width: 100%;
          font-size: 24px;
        }

        width: 44%;
        font-size: 13px;

        .linkWrapper {
          margin-bottom: 55px;
          width: 120%;
        }
      }
    }
  }

  @media all and (max-width: 1300px) {

    .HomepageAboutContent {

      .slider-wrapper {
        width: 55%;
        margin-right: 0;
      }

      .FeaturesWrapper {
        width: 48%;
        font-size: 13px;
        .features{
          .features-item{
            span{
              font-size: 12px;
            }
          }
        }

        .features-text {
          line-height: 22px;
          font-size: 13px;
        }

        .linkWrapper {
          margin-bottom: 20px;
          width: 108%;
        }

        .btnsWrapper {
          button {
            font-size: 15px;
            height: 42px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1210px){
    .HomepageAboutContent{
      .linkWrapper{
        width: 140%!important;
      }
    }
  }

  @media all and (max-width: 1000px) {
    padding: unset!important;
    .HomepageAboutTitle {
      font-size: 26px;
    }

    .HomepageAboutContent {
      margin-top: 50px;
      flex-direction: column;
      height: unset;
      padding-left: 2%;

      .slider-wrapper {
        width: 100%;
        margin-right: 0;

        .images-list-wrapper {
          .slider-btn {
            visibility: hidden;
          }
        }
        .images-list-wrapper {
          &:hover .slider-btn {
            visibility: hidden;
          }

        }
      }

      .FeaturesWrapper {
        position: unset;
        width: 100%;
        margin-left: 0;
        margin-top: 30px;
        font-size: 13px;

        .features-title {
          font-size: 3.5vw;
          margin-bottom: 30px;
        }

        .features-text {
          font-size: 14px;
          max-height: 440px;
        }
        .linkWrapper{
          width: 78%!important;
          margin-top: 40px;
          margin-bottom: 20px;
        }

        .features {
          margin-bottom: 20px;
        }
        .btnsWrapper {
          button {
            font-size: 14px;
            height: 45px;
          }
        }
      }
      .About-img-wrapper{
        width: 90%;
        margin-top: 60px;
      }
    }
  }

  @media screen and (max-width: 800px){
    .HomepageAboutContent{
      padding-left: 6%;
      .FeaturesWrapper{
        .linkWrapper{
          width: 84%!important;
        }
      }
    }
  }

  @media all and (max-width: 600px) {
    .HomepageAboutTitle {
      margin: 30px 0;
      font-size: 22px;
    }

    .HomepageAboutContent {

      .About-img-wrapper{
        .img-block{
          right: 24px!important;
          bottom: 24px!important;
        }
      }

      .slider-wrapper {
        .images-list-wrapper {
          .slider-btn {
            width: 30px;
            height: 30px;

            img, svg {
              width: 12px;
              height: 12px;
            }

            &.prev-btn {
              left: -15px;
            }

            &.next-btn {
              right: -5px;
            }
          }
        }
      }

      .FeaturesWrapper {
        width: 100%;
        margin-left: 0;
        margin-top: 30px;
        font-size: 13px;

        .features {

          .features-item {

            img {
              width: 40px;
              height: 40px;
              margin-right: 10px;
            }

            span {
              width: calc(100% - 60px);
              line-height: 22px;
            }

            &:nth-child(2n) {

              img {
                margin-left: 10px;
                margin-right: 10px;
              }

              span {
                width: calc(100% - 80px);
              }
            }

            &:first-child {
              padding: 0 10px 10px 0;
            }

            &:last-child {
              padding: 10px 0 0 0;
            }

            &:nth-child(2) {
              padding: 0 0 10px 0;
            }

            &:nth-child(3) {
              padding: 10px 10px 0 0;
            }
          }
        }

        .linkWrapper {
          width: 100%!important;
          span{
            font-size: 12px;
            width: 50%;
          }
          .features-btn-see-all{
            font-size: 11px;
            padding: 0 8px 0 8px ;
            width: 46%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
  @media all and (max-width: 450px) {

    padding-bottom: 40px;
    .HomepageAboutTitle {
      font-size: 18px;
    }

    .HomepageAboutContent {

      .slider-wrapper {
        .images-list-wrapper {
          .slider-btn {
            width: 25px;
            height: 25px;

            img, svg {
              width: 10px;
              height: 10px;
            }

            &.prev-btn {
              left: -10px;
            }

            &.next-btn {
              right: 0;
            }
          }
        }
      }

      .FeaturesWrapper {
        font-size: 12px;

        .features-title {
          font-size: 4.5vw;
          margin-bottom: 20px;
        }

        .features-text {
          line-height: 20px;
        }

        .features {
          margin-bottom: 10px;

          .features-item {

            img {
              width: 32px;
              height: 32px;
            }

            span {
              line-height: 18px;
            }
          }
        }

        .btnsWrapper {
          button {
            font-size: 12px;
            height: 40px;
            padding: 0 20px;
          }
        }
      }
    }
  }
  @media all and (max-width: 370px) {
    .HomepageAboutTitle {
      font-size: 16px;
    }

    .HomepageAboutContent {

      .FeaturesWrapper {

        .features {
          .features-item {
            width: 100%;
            border: none;

            &:nth-child(2n) {

              img {
                margin-left: 0;
              }

              span {
                width: calc(100% - 70px);
              }
            }

            &:first-child {
              border-right: 0;
            }

            &:last-child {
              border-left: 0;
            }

            &:nth-child(2) {
              padding-top: 10px;
              border-left: 0;
              border-bottom: 0;
            }

            &:nth-child(3) {
              border-right: 0;
              padding-bottom: 10px;
            }
          }
        }

        .features-title {
          font-size: 5vw;
        }

      }
    }
  }
}
