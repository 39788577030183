@import "../../assets/styles/mainVariables";

.Homepage {
  width:calc(100% - 370px);
  height: 100%;
  position: relative;
  top: 100px;
  left: 370px;

  .sliderImg-Wrapper{
    position: fixed;
    top:136px;
    left: 0;
    width:100%;
    height: 100%;
    .img-Wrapper{
      position: absolute;
      left: 370px;
      width: 80%;
      height: 80vh;
    }
  }

  .bottom-block{
    position: relative;
    background-color: white;
    margin-top: 100vh;
    padding-left:4%;
  }

  .loader-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - 80px);
    background-color: white;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }

  @media screen and (max-width: 1000px){
    width: 100%!important;
    left: 0!important;
    .sliderImg-Wrapper{
      .img-Wrapper{
        width: 100%;
        left: 0;
      }
    }
    .bottom-block{
      padding-left: 2%;
      padding-right: 2%;
    }
  }
  @media screen and (max-width: 400px){
    .bottom-block{
     margin-top: 56vh;
    }
  }
}
