@import '../../assets/styles/mainVariables';
@import '../../assets/styles/fonts';

.Currency{
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  .sale{
    font-size: 14px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0.02em;
    margin-top: 16px;
    display: flex;
    align-items:baseline;
    svg{
      margin-right: 6px;
    }
    p{
      width: 30px;
      display: flex;
      justify-content: flex-end;

    }
    &:nth-child(2){
      padding-right: 1px;
    }
  }
  .currency-exchange{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .change-block{
      width: 90%;
      display: flex;
      justify-content: flex-end;
      align-items: baseline;
      flex-direction: row;
      border-bottom: 1px solid #f0f0f0;
      span{
        margin-bottom: 10px;
        width: 100%;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.03em;
        text-align: left;
        color: black;

      }
      .ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before,
      .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before{
        border: unset !important;
        display: none;
      }
      .ant-tabs-ink-bar{
        background: unset !important;
      }
      .ant-tabs-tab{
        font-size: 12px;
        font-weight: 300;
        line-height: 18px;
        letter-spacing: 0.03em;
        text-align: left;
        color:#282825;
        :hover{
          color: black!important;
        }
      }
      .ant-select-item-option-active .ant-select-item-option-selected{
        cursor: no-drop!important;
      }
      .ant-tabs-tab-btn:focus{
        color:black !important;
      }
      .ant-tabs-nav{
        margin: unset !important;
      }
    }
    .currency-wrapper{
      width: 80%;
      display: flex;
      align-items: center;
      justify-content:flex-end;
      margin-right: 36px;
        .purchase {
          font-size: 14px;
          font-weight: 400;
          line-height: 15px;
          letter-spacing: 0.02em;
          padding-left: 60px;
          margin-top: 16px;
          margin-right: 60px;
        }

      .sale-currency{
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 1000px){
          &:nth-child(2){
            padding-right: 22px;
          }
        }
      }
      .country{
        font-size: 14px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0.02em;
        text-align: left;
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
         width: 60%;
      }
    }
  }
  .calculator-wrapper{
    width: 78%;
    display: flex;
    flex-direction: column;
    margin: 42px 0 70px 38px;
    .calculator-title{
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.03em;
      color: #282825
    }
    .date{
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.03em;
      text-align: left;
      color: #BCA477;
    }
    .calculator{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      margin-bottom: 40px;
      .custom-input-group{
        .ant-select{
          margin-bottom: 20px;
        }
        .ant-select:not(.ant-select-customize-input) .ant-select-selector{
          height: 44px;
          display: flex;
          align-items: center;
        }
        input{
          height: 44px;
        }
      }
      .left-block{
        margin-top: 20px;
        padding-right: 8px;
      }
      .right-block{
        padding-left: 8px;
      }
      .change-icon{
        height: 44px;
        display: flex;
        align-items: center;

        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: 1000px){
    .change-block{
      .ant-tabs{
        width: 80%!important;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 11%;
        .ant-tabs-nav{
          width: 100%;
          display: flex;
          justify-content:flex-end;
          .ant-tabs-nav-wrap{
            width: 100%;
            .ant-tabs-nav-list{
              width: 100%!important;
              display: flex!important;
              justify-content:flex-end !important;

            }
            .ant-tabs-tab{
              width: 100%!important;
              margin: unset!important;
            }
            :nth-child(2){
             padding-left: 46%;
            }
          }
          .ant-tabs-nav-operations{
            display:none!important;;
          }
        }
      }
    }
    .currency-exchange{
      .change-block{
        width: 79%;
      }
      .currency-wrapper{
        margin-right: 9%!important;
        width: 70%;
      }
    }
    .calculator-wrapper{
      width: 70%;
      margin-left: 21%;
    }
  }

  @media screen and (max-width: 500px){
    .change-block{
      .ant-tabs{
        width: 100%!important;
        .ant-tabs-nav{
          .ant-tabs-nav-wrap{
            :first-child{
              padding-left: 30px;
            }
            :nth-child(2){
              padding-left: 20%
            }
          }
        }
      }
    }
    .currency-exchange{
      .change-block{
        width: 90%;
      }
      .currency-wrapper{
        width: 80%;
      }
    }
    .calculator-wrapper{
      width: 80%;
      margin-left:38px;
    }
  }
}
