@import "../fonts";
@import "../mainVariables";

.footerWrapper {
  background-color:#FEFEFE;
  width: calc(100% - 370px);
  position: relative;
  left: 370px;
  top: 40px;
  bottom: 0;
  padding-left: 4%;


  .telephones{
    display: flex;
    svg{
      margin-right: 10px;
      padding-bottom: 4px;
    }
    p{
      margin-top: 4px;
      font-size: 18px!important;
      font-weight: 500;
    }
  }

  .privacy-policy{
    padding: 0 2% 0 6.6%;
    position: absolute;
    bottom: 116px;
    color: #484848;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: 0.2s;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.03em;
    text-align: left;
    @media screen  and (max-width: 1200px){
      font-size: 12px;
    }
    @media screen  and (max-width: 1000px){
      bottom: 240px;
      font-size: 16px;
      padding: 0 2% 0 4%;
      width: 40%;
    }
    @media screen and (max-width:600px){
      bottom: 260px;
    }
    :hover{
      color:$brandColor;
    }
  }

  .topFooterBlock{
    width: 100%;
    background-color:$brandColor;
    height: 96px;
    display: flex;
    align-items: center;
    .topFirstBlock{
      width: 50%;
      color:#FEFEFE;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      padding-left: 7%;
      .BlocksTitle{
        font-weight: 700;
        line-height: 28px;
        font-size: 19px;
        letter-spacing: 0.03em;
        text-align: left;

      }
      .Address{
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.03em;
        text-align: left;

      }
    }
    .questionsBlock{
      width: 50%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      padding-right: 5%;
      color:#FEFEFE;
      .questionsTitle{
        font-size: 22px;
        line-height: 34px;
        letter-spacing: 1px;
      }
      .number{
        cursor: pointer;
        margin-left: 18px;
        line-height: 34px;
        font-size: 23px;
        letter-spacing: 0.03em;
        text-align: left;

      }
    }
  }
  .top-side {
    padding: 120px 14% 30px 7%;
    justify-content: space-between;
    display: flex;
    background: #FEFEFE;

    div{
      color: #484848!important;
    }

    @media screen and (max-width:1600px){
      padding:120px 2% 30px 7%;
    }
    .mobile-first-block{
      padding-top:11px;
      :nth-child(2){
        display: none;
      }
    }
    .mobile-second-block{
      width: 55%;
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      @media screen and (max-width: 1400px){
        :first-child{
          .title{
            font-size: 12px;
          }
        }
      }
      @media screen and (max-width: 1200px){
        .col{
          .title{
            font-size: 12px!important;
            width: 80%;
          }
          a{
            p{
              font-size: 12px;
            }
          }
        }
        .subscribeBlock{
          width: 80%!important;
        }
      }
    }
    .social-block{
      padding-top: 11px;
    }
    .col {
      &:nth-child(3){
        padding-top: 12px;
      }
      &:first-child{
        padding-top: 12px;
        .title{
          font-size: 16px;
        }
      }
      .logo-wrapper {
        img {
          height: 50px;
          margin-bottom: 44px;
        }

        p{
          margin-bottom: 8px;
        }
      }
      .subscribeBlock{
        width: 100%;
        display: flex;
        align-items:baseline;
        justify-content: space-between;
        border-bottom: 1px solid #D2D2D2;
        .custom-input-group{
          width: 90%;
         input{
           width: 100%;
           border:0;
         }
        }
        .subscribeBtn{
          background: transparent;
          svg {
            transition: transform 0.2s;
            transform: rotate(0deg);

            path {
              fill: $brandColor;
            }
          }

          &:hover {
            svg {
              transform: rotate(0deg) translateX(-6px);
            }
          }
        }
      }
      .invalid{
        border-bottom: 1px solid red;
      }

      .title {
        color: $textColor;
        margin: 4px 0 30px 0;
        font-size: 20px;
        width:310px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      a p {
        transition: .2s;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.03em;
        text-align: left;


        &:hover {
          color: $brandColor;
        }
      }

      p {
        color:$textColor;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        margin-bottom: 14px;
      }

      .social-wrapper {
        width: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        margin-top: 22px;

        svg {
          cursor: pointer;
        }
      }
    }

    &.desktop {
      display: flex;
    }

  }
  .developed-by{
    background-color: #FEFEFE;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 7.5% 30px 0;
    svg{
      margin-left: 12px;
    }
  }
  .bottom-side {
    height: 32px;
    padding: 20px var(--mainPadding);
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #EEEEEE;
    color:#696969;

  }

  @media all and (max-width: 1400px) {
    .topFooterBlock{
      .questionsBlock{
        width: 56%;
        .questionsTitle{
          font-size: 16px!important;
        }
        .number{
          font-size: 16px;
        }
      }
    }
    .mobile-second-block{
      width: 70%!important;
    }
    .top-side {
      .col {
        .title {
          font-size: 14px;
          margin: 10px 0 30px 0;
        }

        p {
          font-size: 16px;
          margin-bottom: 14px;
        }
      }
    }

  }

  @media all and (max-width: 1200px) {
    .mobile-first-block{
      .col{
        a{
          p{
            font-size: 12px;
          }
        }
      }
    }
    .social-block{
      .col{
        .title{
          font-size: 12px!important;
        }
        .social-wrapper{
          a{
            svg{
              width: 70px;
            }
          }
        }
      }
    }

  }

  @media all and (max-width: 1000px) {
    width: 100%;
    left: 0;
    padding-left: unset;
    .topFooterBlock{
      .topFirstBlock{
        width: 42%;
        .BlocksTitle{
          font-size: 16px;
        }
        .Address{
          font-size: 13px;
        }
      }
      .questionsBlock{
        width: 56%;
        flex-direction: column;
      }
    }
    .top-side {
      width: 90%;
      display: flex;
      align-items: baseline;
      justify-content: center;
      margin: 0 8% 0 4% ;
      padding: 10px 0 30px;
      .mobile-first-block{
        .col{
          &:nth-child(2){
            padding-top: 78px;
          }
        }
      }
      .col{
        a p {
          font-size: 16px;
          margin-bottom: 11px;
        }

        .title {
          font-size: 16px;
          margin: 10px 0 14px 0;
        }

        .social-wrapper{
          margin-top: unset;
          align-items: flex-start;
          a{
            svg{
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      .social-block{
        display: none;
      }
      .mobile-first-block{
        width:50%;
        :nth-child(2){
          display: flex;
          flex-direction: column;
        }
      }
      .mobile-second-block{
        width: 50%;
        :first-child{
          .title{
            font-size: 16px;
          }
          .subscribeBlock{
            margin-bottom: 80px;
          }
        }
      }

      .social-wrapper {
        width: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        svg {
          cursor: pointer;
          width: 22px;
          height: 22px;
        }
      }
    }
    .developed-by{
      position: relative;
      bottom: 58px;
      background-color: unset;
      padding: 0 7% 30px 0;
    }
    .bottom-side {
      height: 48px;
    }
  }
  @media screen and (max-width: 620px){
    .top-side{
      .mobile-second-block{
        width: 50%;
        flex-direction: column;
      }
    }
    .developed-by{
      position: unset;
      background-color: white;
    }
  }

  @media all and (max-width: 500px) {

    .topFooterBlock{
      .questionsBlock{
        width: 56%;
        flex-direction: column;
        .questionsTitle{
          font-size: 14px!important;
        }
        .number{
          font-size: 16px!important;
        }
      }
    }
    .top-side {
      .col2{
        a p {
          font-size: 12px;
          margin-bottom: 14px;
        }
        .title {
          font-size: 12px;
          margin: 10px 0 14px 0;
        }
      }

      .mobile-second-block{
        width: 50%;
        flex-direction: column;
        :first-child{
          .title{
            width: 166px!important;
            font-size: 12px;
          }
          .subscribeBlock{
            margin-bottom: 80px;
          }
        }
      }
    }
    .developed-by{
      padding: 0 6% 30px 0;
      font-size: 12px;
    }
  }
  .bottom-side {
    div{
      font-size: 12px;
    }
  }

}

.languages-wrapper {
  width: 82px;

  .language-dropdown-item {
    display: flex;
    align-items: center;
    text-transform: uppercase;


    img {
      width: 20px;
      height: 14px;
      margin-right: 8px;
    }
  }
}

.dropdown-menu-item {
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1px;
  cursor: pointer;

  div {
    position: relative;
    font-size: 13px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: -0.01em;
    text-align: right;
    color: #272727;
    margin-right: 8px;
  }

  img {
    width: 24px;
    height: 20px;
    margin-right: 10px;
    border-radius: 1px;
  }

}
