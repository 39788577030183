@import "../../assets/styles/fonts";
@import "../../assets/styles/mainVariables";

.ContactWrapper {
  width: calc(100% - 370px);
  position: relative;
  left: 370px;
  top: 135px;
  .custom-input-group{
    input{
      border-radius: unset!important;
    }
  }
  .ContactImageWrapper {
    position: relative;
    .full-screen-icon{
      position: absolute;
      right: 8%;
      bottom: 18px;
      z-index: 999;
      cursor: pointer;
    }

    .map-wrapper {
      width: 100%;
      height: 300px;
      position: relative;

      .yandexMap {
        width: 100%;
        height: 100%;

        .ymaps-2-1-79-map {
          width: 100%;
          height: 100%;
        }
      }
    }
    .contact-bg-img {
      width: 100%;
    }
  }

  .ContactMainContent {
    padding: 0 4% 10% 6% ;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    .LeftSide {
      position: absolute;
      top: -60px;
      width:34%;
      background: #CDBB99;
      box-shadow: 3px 10px 37px rgba(0, 0, 0, 0.08);
      color:#FEFEFE ;
      display: flex;
      flex-direction: column;
      padding:32px 8% 0 32px ;

      .inside-block{
        .subtitle {
          margin-bottom: 10px;
          font-size: 23px;
          font-weight: 700;
          line-height: 28px;
          letter-spacing: 0.03em;
          text-align: left;

        }
        .description {
          width: 100%;
          color: #666;
          letter-spacing: 0.4px;
          margin-bottom: 12px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          span{
            display: flex;
            align-items: center;
            width: 12%;
            svg{
              width:36px ;
              height:36px ;
            }
          }
          p{
            width: 91%;
            font-size: 13px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0.04em;
            text-align: left;
            color: #FEFEFE;
            display: flex;
            align-items: center;
          }
        }
        .line {
          width: 100%;
          border-top: 1px solid #F2F2F2;
        }
        .item-card {
          display: flex;
          align-items: center;

          .icon-wrapper {
            height: 50px;
            width: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 6px;
            background-color: #F5F6F7;
            margin-right: 20px;
            transition: .2s;

            &:hover {
              background-color: $brandColor;
            }
          }

          .item-card-content-text {
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 24px;
            font-weight: 700;
            line-height: 36px;
            letter-spacing: 0.03em;
            text-align: left;
            color: #FEFEFE;
            margin-top: 20px;
            margin-bottom: 32px;
          }
        }
      }
    }

    .RightSide {
      margin-top: 220px;
      width: 70%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .input-title{
        font-size: 33px;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 0.03em;
        text-align: left;
      }
      .input-description{
        font-size: 19px;
        line-height: 28px;
        letter-spacing: 0.03em;
        text-align: left;
        margin: 10px 0 40px 0;
      }

      .fields-wrapper{
        width: 100%;
        height: 190px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left-block{
          width: 48%;
          height: 100%;
          justify-content: space-between;
          flex-direction: column;
          display: flex;
          .custom-input-group{
            input{
              height: 44px;
            }
          }
        }
        .right-block{
          width: 48%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          padding-bottom: 10px;
          .custom-input-group{
            textarea{
              height: 112px;
            }
          }
          .PrimaryButton{
            color:#FEFEFE;
            font-size: 13px;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0.03em;
            text-align: left;
            &:hover{
              background-color:$hoverColor;
            }
          }
        }
      }
      .anticon-loading {
        margin-left: 10px;

        svg {
          path {
            fill: #202020;
          }
        }
      }

      .tick-icon {
        height: 24px;
        width: 24px;
        margin-left: 10px;
        transition: all 0.2s;
        visibility: visible;

        path {
          fill: #202020;
        }
      }

      .show-tick {
        visibility: visible;
        opacity: 1;
      }

      .hide-tick {
        visibility: hidden;
        opacity: 0;
        width: 0;
        margin-left: 0;
      }
    }
  }

  @media all and (max-width: 1600px){
      .ContactMainContent{
        .LeftSide{
          width: 48% !important;
        }
      }
  }
  @media all and (max-width: 1200px){
      .ContactMainContent{
        .LeftSide{
          width: 62% !important;
        }
      }
  }


  @media all and (max-width: 1400px) {
    .ContactMainContent{
      padding-left: 6%;
      .LeftSide{
        .inside-block{
          .subtitle{
          }
          .contact-items{
            a{
              font-weight: 700;
            }
            .item-card{
              .item-card-content-text{
                font-size: 20px;
              }
            }
          }
        }
      }
      .RightSide{
        width: 86%;
        .input-title{
          font-size: 23px;
        }
        .input-description{
          font-size: 15px;
        }
      }
    }
  }
  @media all and (max-width: 1000px) {
    width: 100%;
    left: 0;
    padding: 0 20px 9% 20px;
    .AddressWrapper{
      width: 94%;
      padding-right: 7% !important;
    }
    .ContactImageWrapper{
      display: none;
    }
    .ContactMainContent{
      padding: unset;
      .LeftSide{
        top: unset;
        width: 100%!important;
        .inside-block{
          .description{
            width: 68%;
          }
        }
      }
      .RightSide{
        padding-top: 30px;
        width: 100%;
        margin-bottom: 60px;
      }
    }
  }
  @media all and (max-width: 800px) {
    .ContactMainContent{
      .RightSide{
       .fields-wrapper{
         flex-direction: column;
         height: unset;
         .left-block{
           width: 100%;
         }
         .right-block{
           width: 100%;
         }
       }
      }
    }
  }

  @media all and (max-width: 430px) {
    .ContactMainContent{
      .inside-block{
        .subtitle{
          font-size:16px!important;
        }
        .description{
          width: 100%!important;
        }
      }
    }
  }
}


