@import "../mainVariables";

.headerWrapper {
  width: 100%;
  position: fixed;
  height: 136px;
  top: 0;
  background-color: $brandColor;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;


  .desktop-menu {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .mobile-menu {
    display: none;
    padding: 0 0 0 $mainPadding;
    background-color: $brandColor;
    position: fixed;
    top: 0;
    z-index: 1000;
  }

  .left-side {
    padding-left: 3%;
    width: 12%;
    display: flex;
    align-items: center;

    .logo-wrapper {
      display: flex;
      align-items: center;
      height: 86px;
      img {
        height: 100%;
      }
      @media screen and (max-width: 1200px){
        height: 70px;
      }
    }
  }

  .right-side {
    width: 40%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    height: 100%;

    .top-side {
      width: 100%;
      display: flex;
      height: 100%;
      justify-content: flex-end;

      .menu-items-list {
        height: 100%;
        display: flex;
        align-items: center;
        list-style-type: none;
        margin-bottom: 0;
        padding: 24px 7% 24px 0;
        justify-content: flex-end;
        border-bottom: 1px solid  #FFFFFF4D;
        li {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content:center;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: 0.04em;
          text-align: left;
          margin-left:10px;
          &:first-child{
            margin-left:0 !important;
          }
          :last-child {
            .menu-item {
              margin-right: 10px;
            }
          }
        }

        .menu-item {
          height: 100%;
          display: flex;
          padding: 0 8px;
          position: relative;
          color: #fff;
          list-style: none;
          cursor: pointer;
          justify-content: center;
          align-items: center;
          user-select: none;
          user-focus: none;
          font-size: 14px;
          white-space: nowrap;
          border-bottom: 3px solid transparent;
          &.active {
            transition: all .3s ease-in-out;
            border-bottom: 2px solid rgba(255, 255, 255, 0.71);
            height: 100%;
          }
          &:after {
            display: block;
            position: absolute;
            top: 38px;
            left: 0;
            width: 0;
            height: 2px;
            background-color: rgba(255, 255, 255, 0.71);
            content: "";
            transition: width 0.3s ease-out;
          }
          &:hover:after{
            width: 100%;
          }
        }

        .header-phone {
          width: 160px;
          background: #FEFEFE;
          height: 40px;
          padding: 13px 26px;
          color: #A18E77;
          border-bottom: none;
          margin-left: 20px;
          font-size: 13px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0.04em;
          text-align: left;


          span {
            margin-top: 2px;
          }
        }
      }
    }
    .bottom-side{
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 7px 6.4% 0 0;
      span{
        color: #FEFEFE;
        margin:0 20px 5px  ;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.05em;
        text-align: left;
      }
      .icon-wrapper{
        display: flex;
        justify-content: space-between;
        svg{
          &:first-child{
            margin-right: 13px;
          }
        }
      }
    }
  }

  .nav-header {
    display: none;
  }

  .burger {
    position: absolute;
    top: 38%;
    transform: translateY(-50%);
    right: 25px;
    width: 30px;
    height: 30px;
    transition: -webkit-transform .5s cubic-bezier(.785, .135, .15, .86);
    transition: transform .5s cubic-bezier(.785, .135, .15, .86);
    transition: transform .5s cubic-bezier(.785, .135, .15, .86), -webkit-transform .5s cubic-bezier(.785, .135, .15, .86);
    cursor: pointer;
    display: none;
    z-index: 1100;

    &:hover {
      transition: color .5s cubic-bezier(.785, .135, .15, .86), border .5s cubic-bezier(.785, .135, .15, .86), background .5s cubic-bezier(.785, .135, .15, .86), opacity .5s cubic-bezier(.785, .135, .15, .86);
    }
  }

  .burger::after, .burger::before {
    content: '';
    position: absolute;
    left: 0;
    width: 30px;
    height: 1px;
    background: #FEFEFE;
    transition: width .5s cubic-bezier(.785, .135, .15, .86), background .5s cubic-bezier(.785, .135, .15, .86), -webkit-transform .5s cubic-bezier(.785, .135, .15, .86);
    transition: width .5s cubic-bezier(.785, .135, .15, .86), background .5s cubic-bezier(.785, .135, .15, .86), transform .5s cubic-bezier(.785, .135, .15, .86);
    transition: width .5s cubic-bezier(.785, .135, .15, .86), background .5s cubic-bezier(.785, .135, .15, .86), transform .5s cubic-bezier(.785, .135, .15, .86), -webkit-transform .5s cubic-bezier(.785, .135, .15, .86);
  }

  .burger:hover::after {
    width: 30px;
    -webkit-transition: width .25s cubic-bezier(.785, .135, .15, .86), background .5s cubic-bezier(.785, .135, .15, .86), -webkit-transform .5s cubic-bezier(.785, .135, .15, .86);
    transition: width .25s cubic-bezier(.785, .135, .15, .86), background .5s cubic-bezier(.785, .135, .15, .86), -webkit-transform .5s cubic-bezier(.785, .135, .15, .86);
    transition: width .25s cubic-bezier(.785, .135, .15, .86), background .5s cubic-bezier(.785, .135, .15, .86), transform .5s cubic-bezier(.785, .135, .15, .86);
    transition: width .25s cubic-bezier(.785, .135, .15, .86), background .5s cubic-bezier(.785, .135, .15, .86), transform .5s cubic-bezier(.785, .135, .15, .86), -webkit-transform .5s cubic-bezier(.785, .135, .15, .86);
  }

  .burger::before {
    top: 12px;
  }

  .burger::after {
    top: 22px;
    width: 21px;
  }
  @media all and (max-width: 1050px) {
    .burger {
      display: block;
    }
  }
  @media screen and (max-width: 1400px) {
    .left-side {
      width: 4%;
    }
    .right-side {
      .top-side {

        .menu-items-list {
          padding-right: 3%;
          li {
            .menu-item {
            }
          }
        }
      }
      .bottom-side{
        padding-right: 2.4%;
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .right-side {
      .top-side {

        .menu-items-list {
          padding-right: 3%;
          li {
            .menu-item {
            }
          }
        }
      }
      .bottom-side{
        padding-right: 2.4%;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    height: unset;
    .desktop-menu {
      display: none;
    }

    .mobile-menu {
      transition: .2s ease-in-out;
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content:space-between;
      .left-side{
        padding: unset;
        .logo-wrapper{
          height: 42px;
        }
      }
      .burger-wrapper{
        width: 60px;
        height: 60px;
        background-color:#A18E77;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 20px;
      }
      a{
        color: #333333;
        text-decoration: none !important;
      }

      a:hover {
        transition: color .5s cubic-bezier(.785, .135, .15, .86), border .5s cubic-bezier(.785, .135, .15, .86), background .5s cubic-bezier(.785, .135, .15, .86), opacity .5s cubic-bezier(.785, .135, .15, .86);
      }

      .close-menu {
        background-color: transparent !important;
        z-index: 1000;

        &:before {
          background: black;
          transform: rotate(45deg) translate3d(0, 7.5px, 0);
        }

        &:after {
          width: 30px;
          background: black;
          transform: rotate(-45deg) translate3d(0, -7.5px, 0);
        }
      }
    }
    .burger::before{
      top: 18px !important;
      left: 12px !important;
    }
    .burger::after{
      top: 28px !important;
      left: 12px !important;
    }

  }

  @media screen and (max-width: 500px) {
    .left-side {
      .logo-wrapper {

        .header-logo {
          height: 28px;
          width: 28px;
        }
      }
    }
  }

}

.expand_on {
  overflow: hidden;
  height: calc(100vh - 82px);
}

.menu-dropdown {
  visibility: hidden;
  width: 100%;
  position: fixed;
  top: 60px;
  z-index: 1000;
  background-color: white;
  height: 0;
  transition: all .3s;
  overflow: hidden;

  &.open {
    overflow: auto;
    height: calc(100vh - 60px);
    visibility: visible;
  }
  .close-icon{
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    cursor: pointer;
    svg{
      width: 24px;
      height: 24px;
      margin: 16px;
    }
  }


  .menu-items-list {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding: 10px $mainPadding;

    .ant-collapse-header {
      padding: 0 !important;
      .ant-collapse-arrow {
        right: -4px !important;
        width: 24px;
        padding-top: 0 !important;
        transition: all 0.4s ease;

        &.rotate {
          transform: rotate(-180deg) !important;
        }
      }
    }

    .ant-collapse-content-box {
      padding: 0 16px !important;
    }

    li {
      width: 100%;

      a {
        width: 100%;
      }
    }

    .menu-item {
      height: 100%;
      width: 160px;
      display: flex;
      padding: 8px 0;
      list-style: none;
      cursor: pointer;
      align-items: center;
      user-select: none;
      user-focus: none;
      white-space: nowrap;
      border-bottom: 2px solid transparent;
      font-size: 24px;
      font-weight: 500;
      line-height: 36px;
      letter-spacing: 0.03em;
      text-align: left;


      &.active {
        color: $brandColor;
        height: 100%;
      }
    }

    .header-phone {
      background: $brandColor;
      height: 40px;
      padding: 0 24px;
      color:white;
      border-bottom: none;
      margin-top: 12px;
      display: flex;
      justify-content: center;
      letter-spacing: 0.03em;
      .phone-number{
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.03em;
      }
    }
    .mobil-icon-wrapper{
      width: 72px;
      margin: 28px 0;
      display: flex;
      justify-content: space-between;
      :nth-child(2){
        svg{
          width: 27px;
          height: 27px;
          margin-top: -2px;
        }
      }
      svg{
        width: 23px;
        height: 23px;
        path{
          fill:$brandColor;
        }
        circle{
          fill:$brandColor;
        }
      }
    }
    .mobil-languages-wrapper{
      height: 50px;
      width: 400px;
      border-bottom: 1px solid #A18E77;
      display: flex;
      justify-content:flex-start;
      cursor: pointer;
      .select-item{
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0.03em;
        margin-right: 8px;
      }
      .language-text{
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.03em;
        text-align: left;
        margin-right: 8px;
      }
    }
    .rights-block{
      width: 100%;
      display: flex;
      justify-content: flex-start;
      margin: 16px 0;
      color: #696969;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0.03em;
      text-align: left;

    }
  }
}

.custom-menu {
  background-color: #232D35 !important;

  .ant-dropdown-menu-item {
    padding: 6px 16px;
    &:hover  .menu-item{
      color: white;
    }

    &:hover {
      background-color: rgba(0,0,0,.2);
    }
  }

  .menu-item {
    color: white;
  }

}
