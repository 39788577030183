@import '../../assets/styles/mainVariables';
@import '../../assets/styles/fonts';

.house-card {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  transition: all .3s ease-in-out;

  .house-building {
    position: absolute;
    top: 12px;
    right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    border-radius: 4px;
    height: 28px;
    width: 28px;
    cursor: pointer;
    background-color: #f5f5f5;
    transition: all .3s ease-in-out;
  }

  .house-number {
    font-size: 16px;
    font-weight: 700;
    color: #F83636;
  }

  .card-img-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;

    img {
      max-width: 100%;
      padding: 10px 0;
      object-fit: contain;
      height: 150px;

    }
  }
  .card-badge{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 26px;
    .price{
      color: #ACACAC;
      font-size: 13px;
      line-height: 18px;
    }
    .sale{
      width: 64%;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #EEEEEE;
      color: #66CD7C;
      font-weight: 400;
      font-size: 9px;
      line-height: 16px;
      padding: 4px  10px;
      @media screen and (max-width: 450px ){
        width: 56%;
      }
    }
  }

  .card-price {
    display: flex;
    align-items: center;
    line-height: 28px;
    font-size: 19px;
    font-weight: 700;
    margin-bottom: 12px;

    @media screen and (max-width: 1400px){
      font-size: 14px!important;
    }

    svg {
      width: 14px;
      height: 14px;
      margin-top: -3px;
    }
  }

  .areas-list {
    width: 100%;
    margin-bottom: 20px;

    .list-item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 15px;
      margin-bottom: 10px;

      .item-name {
        margin-right: 4px;
        color: #7E8794;
        font-size: 13px;
        line-height: 18px;
      }

      .line {
        flex-grow: 1;
        border-top: 1px dotted #ACACAC;
      }

      .item-area {
        margin-left: 4px;
        color: #484848;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
      }
    }
  }

  .card-btns-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .PrimaryButton {
      width: calc(100% - 45px);
      height: 44px;
      font-size: 14px;
      background-color:#EEEEEE;
      .title{
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.03em;
        text-align: left;

      }
      &.upload-btn {
        background-color: #EEEEEE;
        padding: 0;
        width: 44px;
        height: 44px;
        color: #202020;
        margin-left: 20px;
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  &:hover {
    -webkit-box-shadow: 0 0 25px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, .1);
    border-color: transparent;
  }
  &:hover .house-building {
    background-color: $brandColor;
  }

  //@media all and (max-width: 1500px) {
  //  width: calc(100% / 5);
  //}
  //@media all and (max-width: 1200px) {
  //  width: calc(100% / 4);
  //}
  //@media all and (max-width: 900px) {
  //  width: calc(100% / 3);
  //}
  //@media all and (max-width: 700px) {
  //  width: calc(100% / 2);
  //}
  //@media all and (max-width: 370px) {
  //  width: 100%;
  //}
}
