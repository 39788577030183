@import "../../assets/styles/fonts";
@import "../../assets/styles/mainVariables";

.NotFoundWrapper {
  width: calc(100% - 370px);
  position: relative;
  left: 370px;
  top: 140px;
  padding: 12% 9%;
  font-size: 40px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 354px);
  color: $brandColor;
}
