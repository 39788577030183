@import '../../../assets/styles/mainVariables';
@import '../../../assets/styles/fonts';

.clock {
  width: 84px;
  height: 84px;
  position: relative;
  .city{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FEFEFE;
    text-transform: uppercase;
    padding-bottom: 40px;
    font-size: 11px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.04em;
    text-align: left;
  }
  :last-child{
    .city{
      width: 120%;
    }
  }
}
.clocks-block{
  width: 24%;
  margin-left:100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}
.clock-face{
  svg{
    height: 8px;
    width: 8px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 11
  }
}
//.clock::after {
//  content: '';
//  height: 7px;
//  width: 7px;
//  border-radius: 50%;
//  background-color: gray;
//  border: 1.5px solid black;
//  position: absolute;
//  top: 50%;
//  left: 50%;
//  transform: translateX(-50%) translateY(-50%);
//  z-index: 11;
//}


.clock .hand {
  position: absolute;
  width: 30px;
  height: 40px;
  bottom: 50%;
  left: 50%;
  transform-origin: bottom;
  z-index: 10;
}

.clock .hand.second {
  width: 1%;
  height: 38%;
  background-color: #7a0606;
  box-shadow: 0 0.5px 1px 0 #2B2B2BA6;
}
.clock .hand.minute {
  width: 2%;
  height: 38%;
  background-color: black;
  box-shadow: 0 0.5px 1px 0 #2B2B2BA6;
}
.clock .hand.hour {
  width: 3%;
  height: 28%;
  background-color: black;
  box-shadow: 0 0.5px 1px 0 #2B2B2BA6;
}

@media screen  and (max-width: 1400px){
  .clock{
    width: 72px;
    height: 72px;
    .city{
      font-size: 9px;
    }
  }
  .clocks-block{
    width: 25%;
    margin-left: 8%;
  }
}
@media screen  and (max-width: 1200px){
  .clock{
    width: 63px;
    height: 63px;
  }
  .clocks-block{
    margin-left: 10%;
  }
}
@media screen  and (max-width: 1000px){
  .clock{
    width: 96px;
    height: 96px;
    .city{
      font-size: 12px;
      color: black;
    }
  }
  .clocks-block{
    width: 92%;
    margin: 2% 4% 4% 4%;
  }
}

@media screen  and (max-width: 500px){
  .clock{
    width: 66px;
    height: 66px;
    .city{
      color: black;
    }
  }
}
@media screen  and (max-width: 350px){
  .clock{
    width: 60px;
    height: 60px;
    .city{
      color: black;
    }
  }
}
