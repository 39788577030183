@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/fonts";

.custom-input-group {
  position: relative;

  label {
    display: block;
    font-size: 13px;
    font-weight: 500;
    color: #202020;
    margin-bottom: 8px;
  }

  .ant-select-selection-item{
    box-shadow: unset;
  }

  input, textarea, select {
    width: 100%;
    border: 1px solid #DDDEDF;
    padding: 8px;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 400;

    &:not(.ant-select-search__field):focus {
      outline: none;
      border: 1px solid $brandColor;
    }

    &.resizable {
      resize: vertical;
      min-height: 100px;
    }

    &.invalid {
      border: 1px solid #ff0000;
    }

    &:disabled {
      background: #f8f8f8 !important;
    }
  }

  input[type="checkbox"] {
    height: 25px;
    width: 25px;
  }

  input::placeholder {
    color: #808080;
    font-size: 14px;
  }

  textarea {
    resize: none;
    height: 100px;
    &::placeholder {
      color: #ababab;
      font-size: 15px;
    }
  }

  .custom-select {
    position: relative;

    .ant-select-selection--multiple {
      min-height: 50px;

      .ant-select-selection__rendered {
        min-height: 50px;
        display: flex;
        align-items: center;

        li {
          margin-top: 2px;
          margin-bottom: 2px;
        }
      }
    }

    .ant-select-selection--single {
      height: 50px;
      display: flex;
      align-items: center;


      .ant-select-selection__rendered {
        width: 100%;
        overflow: hidden;
      }
    }

    .ant-select-selection-search{
      input{
        box-shadow: unset!important;
      }
    }

    &:focus {
      outline: none;
    }

    &.invalid {
      .ant-select-selector {
        border: 1px solid #ff0000;
      }
    }
  }
  .tree-select-wrapper{
    width: 100%;
    position: relative;
  }

  .ant-select-dropdown {
    .child-option {
      padding-left: 25px;
    }
  }

  .ant-select-arrow {
    height: 100%;
    top: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .custom-suffix-icon {
      transform: none;
      //width: 23px;
      //height: 23px;
      transition: transform 0.2s;
      transform-origin: center;

      &.rotate {
        transform: rotate(180deg);
      }
    }
  }

  .visibility-icon {
    position: absolute;
    bottom: 25px;
    right: 14px;
    height: 18px;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    svg{
      width: 18px;
      path{
        fill: black;
      }
    }

    i {
      font-size: 16px;
      user-select: none;
      -webkit-transition: color 0.3s ease;
      transition: color 0.3s ease;
      color: #10233c;
    }
  }

  &:focus {
    outline: none;
  }
}


.select-options {
  width: 100%;
  max-height: 250px;
  overflow-x: hidden;
  overflow-y: auto;
  background: white;
  border-radius: 4px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  z-index: 50;

  .option-item {
    display: flex;
    align-items: center;
    padding: 5px 12px;
    overflow: hidden;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.65);
    font-weight: normal;
    line-height: 22px;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    -webkit-transition: background .3s ease;
    transition: background .3s ease;

    input {
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }

    &:hover {
      background-color: #e6f7ff;
    }

    &.checked {
      color: rgba(0, 0, 0, 0.65);
      font-weight: 600;
      background-color: #fafafa;
    }
  }
}

